import clsx from 'clsx';
import type { FC } from 'react';
import type { RecipeGroupProps } from '@/types/recipe';
import styles from '@/components/raven/content/Recipe/styles.module.css';
import { RecipeItem } from '@/components/raven/content/Recipe/RecipeItem';

type RecipeListProps = {
	items: RecipeGroupProps['items'];
};

export const RecipeList: FC<RecipeListProps> = ({ items }) => {
	if (!items.length) {
		return null;
	}

	const cleanItems = Array.isArray(items) ? items.filter(Boolean) : [];

	const isIngredient = cleanItems.every((item) => typeof item === 'string');
	const isInstruction = cleanItems.every(
		(item) => typeof item === 'object' && 'media' in item,
	);

	const ListTag = isIngredient ? 'ul' : 'ol';

	return (
		<ListTag
			className={clsx(styles.recipeListItem, {
				[styles.recipeIngredientsList]: isIngredient,
				[styles.recipeInstructionsList]: isInstruction,
			})}
		>
			{items.filter(Boolean).map((item) => {
				const isString = typeof item === 'string';
				const title = isString ? item : item.title;

				return (
					<RecipeItem
						className={
							isIngredient
								? styles.recipeIngredientListItem
								: styles.recipeInstructionItem
						}
						key={title}
						title={title}
						{...(!isString && { media: item.media })}
					/>
				);
			})}
		</ListTag>
	);
};
