import { getAuthor } from '@/utils/get-author';
import type { AuthorEntity } from '@headstartwp/core';
import type { CustomPostEntity } from '@/types/blocks';
import type { CardType, CardProps } from '@/types/card';
import type { Post, WPImage, PostMeta, PostExtended } from '@/types/entities';

export interface CardOptions {
	aspectRatio: `${number}:${number}`;
	hideAuthor: boolean;
	hideCategory: boolean;
	hideDate: boolean;
	hideTags: boolean;
	postVariation: CardType;
}

// convert entity type Post to CustomPostEntity
export const convertPostToCustomPostEntity = (post: Post) => {
	const { authors, categories, id, link, title } = post;
	const customPostEntity: CustomPostEntity = {
		...post,
		authors: authors as unknown as AuthorEntity[],
		categories: categories as [],
		date_gmt: post.date_gmt as string,
		featured_media: post.featured_media as WPImage,
		id: id as number,
		link: link as string,
		tags: [],
		title: title.rendered as string,
	};
	return customPostEntity;
};

export const generateCards = (
	posts: CustomPostEntity[],
	options: CardOptions,
) => {
	const {
		aspectRatio,
		hideAuthor,
		hideCategory,
		hideDate,
		hideTags,
		postVariation,
	} = options;
	const cards: CardProps[] = posts.map((post: PostExtended) => {
		/** TODO: Update to use new primary category function */
		const category =
			post.categories.length > 0 && post.categories[0]?.name !== 'Uncategorized'
				? post.categories[0].name
				: '';
		return {
			aspectRatio,
			author: !hideAuthor
				? getAuthor(post.authors as AuthorEntity[])
				: undefined,
			category: !hideCategory ? category : undefined,
			date: !hideDate ? (post.date as string) : undefined,
			id: post.id as number,
			image: post.featured_media as WPImage,
			link: post.link as string,
			mediaType: 'image',
			title:
				(post.meta as PostMeta)?.promo_title || (post.title as string) || '',
			variation: postVariation || 'stacked',
		};
	});

	return cards;
};
