'use client';

import { Card } from '@/components/raven/Card';
import type { CustomPostEntity } from '@/types/blocks';
import { generateCards } from '@/utils/generate-cards';
import { useRef, type FC, useState, useEffect } from 'react';

import styles from './styles.module.css';

interface PopularProps {
	post_id: number;
	posts: CustomPostEntity[];
	primary_category?: number;
}

export const PopularPosts: FC<PopularProps> = ({
	post_id,
	posts,
	primary_category,
}) => {
	const [cards, setCards] = useState(() =>
		generateCards(posts, {
			aspectRatio: '16:9',
			hideAuthor: false,
			hideCategory: false,
			hideDate: false,
			hideTags: true,
			postVariation: 'list-no-media',
		}),
	);
	const hasFetched = useRef(false);
	useEffect(() => {
		if (hasFetched.current) {
			return;
		}
		hasFetched.current = true;

		const fetchPosts = async () => {
			const response = await fetch(
				`/.api/popular-posts?post_id=${post_id}&primary_category=${primary_category}`,
			);
			if (!response.ok) {
				return;
			}
			const data = await response.json();
			setCards(
				generateCards(data, {
					aspectRatio: '16:9',
					hideAuthor: false,
					hideCategory: false,
					hideDate: false,
					hideTags: true,
					postVariation: 'list-no-media',
				}),
			);
		};

		fetchPosts();
	}, [post_id, primary_category]);

	return (
		<section className={styles.popularPosts}>
			<h2 className={styles.popularPostsTitle}>Trending Stories</h2>
			<ol className={styles.popularPostsList}>
				{cards.map((post) => {
					return (
						<li key={post.id}>
							<Card {...post} />
						</li>
					);
				})}
			</ol>
		</section>
	);
};
