import clsx from 'clsx';
import type { FC } from 'react';
import type { CardProps } from '@/types/card';
import { Card } from '@/components/raven/Card';
import type { ApiResponse } from '@/utils/data/types';
import type { CustomPostEntity } from '@/types/blocks';
import type { BlockProps } from '@headstartwp/core/react';
import { serializePostLookup } from '@/utils/post-helpers';
import { imageSizes } from '@/components/raven/Card/image-sizes';
import type { WPImage, FocalPoint, PostLookup } from '@/types/entities';

import styles from './styles.module.css';

interface ArticleCard5050BlockAttributes {
	align: 'full' | 'wide';
	alignment: 'left' | 'right';
	className?: string;
	colorTheme?: string;
	curationType: 'automatic' | 'manual';
	displayAuthors: boolean;
	displayDate: boolean;
	displayPromoTitle: boolean;
	displayTaxonomyTerms: boolean;
	maxNumberOfPosts?: number;
	mediaFocalPoint?: FocalPoint['focal_point'];
	mediaId?: number;
	mediaImage?: WPImage;
	mediaType?: 'image' | 'video';
	numberOfPosts?: number;
	posts: CustomPostEntity[];
	promoTitle?: string;
	taxonomy?: string;
	terms: number[];
}

export const ArticleCard5050Block: FC<BlockProps> = ({
	block,
	blockContext,
}) => {
	if (!block) {
		return null;
	}

	const {
		align,
		alignment,
		className: blockClassName,
		curationType,
		displayAuthors,
		displayDate,
		displayPromoTitle,
		displayTaxonomyTerms,
		mediaImage,
		mediaType,
		promoTitle,
	} = block.attributes as unknown as ArticleCard5050BlockAttributes;
	let { posts } = block.attributes as unknown as ArticleCard5050BlockAttributes;
	let hasPosts = posts && posts.length > 0;

	if (!hasPosts) {
		if (curationType === 'automatic') {
			const key = serializePostLookup(
				block.attributes as unknown as PostLookup,
				1,
			);
			const uniqueBlocks = (blockContext?.uniqueBlocks || {}) as Record<
				string,
				ApiResponse<CustomPostEntity[]>
			>;

			if (blockContext && key in uniqueBlocks) {
				const data = uniqueBlocks[key];
				posts = data.json;
				hasPosts = posts && posts.length > 0;
			}
		}
	}

	if (!hasPosts) {
		return null;
	}

	const firstPost = posts[0];
	const hasAuthors = firstPost.authors && firstPost.authors.length > 0;
	const hasCategories = firstPost.categories && firstPost.categories.length > 0;
	const showDate = displayDate && Boolean(firstPost.date_gmt);
	let { title } = firstPost;

	if (displayPromoTitle && promoTitle) {
		title = promoTitle;
	}

	const newPost = {
		align,
		author: displayAuthors && hasAuthors ? firstPost.authors[0]?.name : '',
		category:
			displayTaxonomyTerms && hasCategories
				? firstPost.categories[0]?.name
				: '',
		className: blockClassName || 'is-style-default',
		date: showDate ? firstPost.date_gmt : '',
		id: firstPost.id,
		image: mediaImage || firstPost?.featured_media || null,
		link: firstPost.link,
		mediaType: mediaType || 'image',
		sizeOverride:
			align === 'wide' ? imageSizes['50-50-wide'] : imageSizes['50-50-stretch'],
		title,
		variation:
			alignment === 'left'
				? '50-50-media-top-media-left'
				: '50-50-media-top-media-right',
	} satisfies CardProps;

	return (
		<section
			className={clsx(styles.cardWrapper, {
				[`align${align}`]: align,
			})}
		>
			<Card {...newPost} />
		</section>
	);
};
