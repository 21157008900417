import type { FC } from 'react';
import {
	RecipeDetailItem,
	type RecipeDetailItemProps,
} from '@/components/raven/content/Recipe/RecipeDetails/RecipeDetailItem';

import styles from './styles.module.css';

interface RecipeDetailsProps {
	details: RecipeDetailItemProps[];
}

export const RecipeDetails: FC<RecipeDetailsProps> = ({ details }) => {
	const hasDetails = details.some((detail) => detail.value);

	if (!hasDetails) {
		return null;
	}

	return (
		<section aria-label="Recipe Overview">
			<dl className={styles.recipeStats}>
				{details.map((detail) => (
					<RecipeDetailItem
						icon={detail.icon}
						key={detail.label}
						label={detail.label}
						needsFormatting={detail.needsFormatting}
						value={detail.value}
					/>
				))}
			</dl>
		</section>
	);
};
