import type { FC } from 'react';
import { SafeHtml } from '@headstartwp/core/react';
import { RecipeDetails } from '@/components/raven/content/Recipe/RecipeDetails';
import { RecipeSection } from '@/components/raven/content/Recipe/RecipeSection';
import { NutritionFacts } from '@/components/raven/content/Recipe/NutritionFacts';
import type { RecipeDetailItemProps } from '@/components/raven/content/Recipe/RecipeDetails/RecipeDetailItem';
import type {
	RecipeChildBlock,
	RecipeGroupProps,
	RecipeDetailsProps,
	NutritionFactsProps,
} from '@/types/recipe';

import styles from './styles.module.css';

export interface RecipeProps extends RecipeDetailsProps {
	attribution?: string;
	description?: string;
	ingredients: RecipeChildBlock<RecipeGroupProps>[];
	instructions: RecipeChildBlock<RecipeGroupProps>[];
	legacyNutritionFacts?: string;
	nutrition?: NutritionFactsProps;
	title: string;
}

export const Recipe: FC<RecipeProps> = ({
	attribution,
	cookTime,
	description,
	ingredients,
	instructions,
	legacyNutritionFacts,
	nutrition,
	prepTime,
	servings,
	title,
	totalTime,
}) => {
	const recipeDetails: RecipeDetailItemProps[] = [
		{
			icon: 'Clock',
			label: 'Duration',
			value: totalTime,
		},
		{
			icon: 'Clock',
			label: 'Cook Time',
			value: cookTime,
		},
		{
			icon: 'Servings',
			label: 'Servings',
			needsFormatting: false,
			value: servings,
		},
		{
			icon: 'Clock',
			label: 'Prep Time',
			value: prepTime,
		},
	];

	return (
		<section className={styles.recipe}>
			<header className={styles.recipeHeader}>
				{title && <h2 className={styles.recipeTitle}>{title}</h2>}

				{description && (
					<p className={styles.recipeDescription}>
						<SafeHtml html={description} />
					</p>
				)}

				<RecipeDetails details={recipeDetails} />
			</header>

			<RecipeSection items={ingredients} label="Ingredients" />

			<RecipeSection items={instructions} label="Instructions" />

			<NutritionFacts
				legacyNutritionFacts={legacyNutritionFacts}
				nutritionFacts={nutrition}
			/>

			{attribution && (
				<footer className={styles.recipeFooter}>
					<p className={styles.recipeAttribution}>
						<SafeHtml html={attribution} />
					</p>
				</footer>
			)}
		</section>
	);
};
