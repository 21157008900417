import { clsx } from 'clsx';
import { isClient } from '@/utils/is-client';
import { hasVideo } from '@/utils/has-video';
import { Schema } from '@/components/Schema';
import { cleanPath } from '@/utils/clean-path';
import { Blocks } from '@/components/raven/Blocks';
import { decodeEntities } from '@/utils/post-helpers';
import type { CustomPostEntity } from '@/types/blocks';
import { Outbrain } from '@/components/raven/Outbrain';
import { Hero } from '@/components/raven/content/Hero';
import { useSiteContext } from '@/context/SiteContext';
import { useAdService } from '@/context/AdServiceContext';
import { ArticleProvider } from '@/context/ArticleContext';
import { Container } from '@/components/raven/ui/Container';
import type { Post, AnalyticsData } from '@/types/entities';
import { KeepReading } from '@/components/raven/KeepReading';
import { fromIntersection } from '@/utils/from-intersection';
import { useRef, useMemo, useEffect, forwardRef } from 'react';
import { PopularPosts } from '@/components/raven/PopularPosts';
import { useGaContext } from '@/context/GoogleAnalyticsContext';
import { useHeaderHeight } from '@/utils/hooks/use-header-height';
import { Disclaimer } from '@/components/raven/content/Disclaimer';
import { ScrollSentinel } from '@/components/global/ScrollSentinel';
import { CommentsCTA } from '@/components/raven/Comments/CommentsCTA';
import { useContinuousScroll } from '@/utils/hooks/use-continuous-scroll';
import { CommentsDrawer } from '@/components/raven/Comments/CommentsDrawer';
import { ArticleTimestamp } from '@/components/raven/content/ArticleTimestamp';
import { CommerceConfigContextProvider } from '@/context/CommerceConfigContext';
import {
	events$,
	ARTICLE_ENTER,
	USER_ARTICLE_VIEW,
	ARTICLE_TITLE_ENTER,
	ARTICLE_CONTENT_ENTER,
} from '@/utils/events';

interface SingleArticleProps {
	disclaimer: string;
	index: number;
	isFirst: boolean;
	isLast: boolean;
	post: Post;
	timeZone: string;
}

export const SingleArticle = forwardRef<HTMLElement, SingleArticleProps>(
	({ disclaimer, index, isFirst, isLast, post, timeZone }, ref) => {
		const { sendGaEvent } = useGaContext();
		const { gaVars } = useSiteContext();
		const adService = useAdService();
		const isContinuousScroll = Boolean(useContinuousScroll());
		const isAffiliate =
			post.article_type_slug === 'affiliate' || post.meta.affiliates_enabled;
		const imageCaption =
			post.meta.featured_image_caption ||
			post.featured_media?.caption?.rendered ||
			'';
		const hasVideoContent = hasVideo(post);
		const BlocksMemoized = useMemo(
			() => (
				<ArticleProvider article={post}>
					<Blocks
						analyticsData={{ post }}
						blockContext={post.blocksContext}
						breadcrumbs={post.breadcrumbs}
						content={post.content.rendered ?? ''}
					/>
				</ArticleProvider>
			),
			[post],
		);
		const headerHeight = useHeaderHeight();

		const sentPageView = useRef<Map<string, boolean>>(new Map());
		const hasSentInitial = useRef(false);
		const dedupePageView = (slug: string, cb: () => void) => {
			if (sentPageView.current.has(slug)) {
				return;
			}
			sentPageView.current.set(slug, true);
			cb();
		};
		// For iPhone, limit one widget per page
		const showOutbrain = !(
			isClient &&
			navigator.userAgent.includes('iPhone OS') &&
			index > 0
		);

		useEffect(() => {
			if (isFirst && !hasSentInitial.current) {
				adService.rotateCorrelator();
				adService.lockContinuousScrollCorrelator();
				events$.next({
					type: USER_ARTICLE_VIEW,
					value: { ...post, isInitial: true, scrollIdx: index },
				});
				dedupePageView(post.slug, () => {
					const analyticsData: AnalyticsData = {
						post,
						sendTo: gaVars.platformTracker,
					};
					sendGaEvent('page_view', analyticsData);
				});
				hasSentInitial.current = true;
				events$.next({ type: ARTICLE_ENTER, value: post });
			}
		}, [adService, index, isFirst, post, sendGaEvent, gaVars.platformTracker]);

		const onHeadLeave = (e: IntersectionObserverEntry) => {
			const atTop = headerHeight - e.boundingClientRect.top > 0;
			const shouldUpdate = [
				isClient,
				cleanPath(post.link) !== cleanPath(window.location.href),
				atTop,
				e.target.isConnected,
			];
			if (shouldUpdate.every(Boolean)) {
				const scrollRef = window.location.href; // Need to capture the value before the history change

				const doc = window.document;
				doc.title = decodeEntities(
					(post.meta.meta_title ??
						post.meta.opengraph_title ??
						post.title.rendered) ||
						doc.title,
				);

				// Also synchronize the canonical link
				const canonicalLinkEl: HTMLLinkElement | null = doc.head.querySelector(
					'link[rel="canonical"]',
				);
				if (canonicalLinkEl) {
					canonicalLinkEl.href = post.meta.canonical_url ?? post.link;
				}
				window.history.pushState(null, '', cleanPath(post.link));
				events$.next({
					type: USER_ARTICLE_VIEW,
					value: { ...post, scrollIdx: index },
				});

				// We send the initial article page_view as soon as the component mounts
				if (!isFirst) {
					dedupePageView(post.slug, () => {
						adService.rotateCorrelator();
						const analyticsData: AnalyticsData = {
							post,
							scrollIdx: index,
							scrollRef,
							sendTo: gaVars.platformTracker,
						};
						sendGaEvent('page_view', analyticsData);
					});
				}
			}
			if (atTop) {
				events$.next({ type: ARTICLE_ENTER, value: post });
			}
		};

		const onTailEnter = (e: IntersectionObserverEntry) => {
			if (headerHeight - e.boundingClientRect.top > 0 && e.target.isConnected) {
				events$.next({ type: ARTICLE_ENTER, value: post });
			}
		};

		const heroEntered = useRef(false);
		const heroRef = useRef(null);
		useEffect(() => {
			if (!heroRef.current) return () => {};
			const subscription = fromIntersection(heroRef.current, {}).subscribe(
				(e) => {
					if (e.isIntersecting && !heroEntered.current) {
						heroEntered.current = true;
						events$.next({ type: ARTICLE_TITLE_ENTER, value: post });
					}
				},
			);
			return () => subscription.unsubscribe();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [heroRef.current, post]);

		const contentEntered = useRef(false);
		const contentRef = useRef(null);
		useEffect(() => {
			if (!contentRef.current) return () => {};
			const subscription = fromIntersection(contentRef.current, {}).subscribe(
				(e) => {
					if (e.isIntersecting && !contentEntered.current) {
						contentEntered.current = true;
						events$.next({ type: ARTICLE_CONTENT_ENTER, value: post });
					}
				},
			);
			return () => subscription.unsubscribe();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [contentRef.current, post]);
		const showPopular = isContinuousScroll ? isFirst : true;

		return (
			<ArticleProvider article={post}>
				<CommerceConfigContextProvider postData={post}>
					<ScrollSentinel
						headRootMargin={`-${headerHeight}px 0px 0px`}
						onHeadLeave={onHeadLeave}
						onTailEnter={onTailEnter}
					>
						<article className="article-post" ref={ref}>
							<Hero
								analyticsData={{ post }}
								authors={post.authors.length > 0 ? post.authors : null}
								caption={imageCaption}
								className={clsx({ 'is-style-pairing-4': isAffiliate })}
								contributorCredits={post.meta.contributor_credits}
								description={post.excerpt.rendered ?? ''}
								image={post.featured_media}
								items={post.breadcrumbs}
								ref={heroRef}
								title={post.title.rendered ?? ''}
								useFallbackVideo={isFirst && !hasVideoContent}
								video={post.meta.featured_video}
							/>
							<Schema schema={post.schema} />
							<div className="content" style={{ position: 'relative' }}>
								{isAffiliate && <Disclaimer disclaimer={disclaimer} />}

								<Container>
									<ArticleTimestamp
										date={post.date_gmt}
										modified={post.modified_gmt}
										timeZone={timeZone}
									/>
									<CommentsCTA post={post} />
								</Container>
								<CommentsDrawer post={post} />

								{BlocksMemoized}

								<div
									ref={contentRef}
									style={{
										backgroundColor: 'rgb(80% 50% 50% / 0.2)',
										bottom: '0',
										display: 'block',
										margin: '0',
										position: 'absolute',
										top: '80vh',
										visibility: 'hidden',
										width: '100%',
									}}
								/>
								<CommentsCTA post={post} />
							</div>
							{showPopular && post.blocksContext?.popularPosts ? (
								<PopularPosts
									post_id={post.id}
									posts={post.blocksContext.popularPosts as CustomPostEntity[]}
									primary_category={post.meta.primary_category_id}
								/>
							) : null}
							{isContinuousScroll && !isLast && <KeepReading />}
							{showOutbrain && <Outbrain />}
						</article>
					</ScrollSentinel>
				</CommerceConfigContextProvider>
			</ArticleProvider>
		);
	},
);

SingleArticle.displayName = 'SingleArticle';
